import React from 'react';
import ObservationForm from './components/ObservationForm';
import ObservationFormEdit from './components/ObservationFormEdit';
import Action from './components/Action';
import ActionVerify from './components/ActionVerify';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primeicons/primeicons.css';
import './App.css'


function App() {
    return (
        <Router>
            <Routes>
                <Route path="/publicObs/:id/:mode" element={<ObservationForm />} />

                <Route path="/publicObs/edit/:id/:mode" element={<ObservationFormEdit />} />

                <Route path="/publicObs/action/:id/:mode" element={<Action />} />

                <Route path="/publicObs/action-verify/:id/:mode" element={<ActionVerify />} />
            </Routes>
        </Router>
    );
}

export default App;
