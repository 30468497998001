import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { FileUpload } from 'primereact/fileupload';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';  // PrimeReact theme
import 'primereact/resources/primereact.min.css';          // Core PrimeReact CSS
import 'primeicons/primeicons.css';
import { LOCATION1_URL, LOCATION2_URL, LOCATION3_URL, GET_USERS_BY_ROLE, LOCATION4_URL, WORK_ACTIVITIES_URL, TIER3_TIER4_URL, TIER4_TIER5_URL, ALL_LOCATION1_URL, QR_OBSERVATION_REPORT_URL_WITH_ID } from '../constants';
import API from '../services/api';
// import LocationSelector from './LocationSelector';
import { useDropzone } from 'react-dropzone';

const ActionVerify = () => {
  
    return (
        <div className="container publicObs-form mt-4">


        </div >
    );
};

export default ActionVerify;
